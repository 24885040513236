import * as React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter,
} from "react-admin";
import MediaInput from "../components/MediaInput";

const ActivityTitle = ({ record }) => {
  return <span>Activity {record ? `"${record.name}"` : ""}</span>;
};

export const ActivityList = (props) => (
  <List {...props} filters={<ActivityFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export const ActivityEdit = (props) => (
  <Edit {...props} title={<ActivityTitle />}>
    <ActivityForm />
  </Edit>
);

export const ActivityCreate = (props) => (
  <Create {...props}>
    <ActivityForm />
  </Create>
);

export const ActivityFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

const ActivityForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" />
    <TextInput multiline source="description" />
    <MediaInput source="image_id" />
  </SimpleForm>
);
