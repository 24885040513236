import * as React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter,
} from "react-admin";
import MediaInput from "../components/MediaInput";

const CityTitle = ({ record }) => {
  return <span>City {record ? `"${record.name}"` : ""}</span>;
};

export const CityList = (props) => (
  <List {...props} filters={<CityFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export const CityEdit = (props) => (
  <Edit {...props} title={<CityTitle />}>
    <CityForm />
  </Edit>
);

export const CityCreate = (props) => (
  <Create {...props}>
    <CityForm />
  </Create>
);

export const CityFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

const CityForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" />
    <TextInput multiline source="description" />
    <MediaInput source="image_id" />
  </SimpleForm>
);
