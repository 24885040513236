import * as React from "react";
import { useCreate, useNotify, useRefresh } from "ra-core";
import { Box, Button, Grid } from "@material-ui/core";
import { Form } from "react-final-form";
import {
  ChipField,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

export const PriceCreate = ({ tour_id }) => {
  // const [create, { loading }] = useCreate("prices");
  const [create] = useCreate("prices");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          // redirect(`/tours/${data.tour_id}/show/prices`);
          notify("ra.notification.created");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm>
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <NumberInput
                    source="num_of_persons"
                    resource="prices"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <NumberInput source="price" resource="prices" fullWidth />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm="auto">
              <Box pt={2}>
                <Button color="primary" variant="contained" type="submit">
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export const AccomodationCreate = ({ tour_id }) => {
  const [create] = useCreate("accommodation_prices");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          notify("ra.notification.created");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm={3}>
              <SelectInput
                source="name"
                resource="accommodation_prices"
                choices={[
                  { id: "silver", name: "Silver" },
                  { id: "gold", name: "Gold" },
                  { id: "diamond", name: "Diamond" },
                ]}
                fullWidth
              />
            </Grid>
            <Grid item sm={9}>
              <TextInput
                source="venues"
                resource="accommodation_prices"
                fullWidth
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Summer Single"
                source="summer_single_room"
                resource="accommodation_prices"
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Summer Double"
                source="summer_double_room"
                resource="accommodation_prices"
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Summer Triple"
                source="summer_triple_room"
                resource="accommodation_prices"
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Winter Single"
                source="winter_single_room"
                resource="accommodation_prices"
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Winter Double"
                source="winter_double_room"
                resource="accommodation_prices"
              />
            </Grid>
            <Grid item sm={2}>
              <NumberInput
                label="Winter Triple"
                source="winter_triple_room"
                resource="accommodation_prices"
              />
            </Grid>
          </Grid>
          <Button color="primary" variant="contained" type="submit">
            Add
          </Button>
          <br />
          <br />
        </form>
      )}
    />
  );
};

export const DayCreate = ({ tour_id }) => {
  // const [create, { loading }] = useCreate("itinerary_days");
  const [create] = useCreate("itinerary_days");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          notify("ra.notification.created");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextInput source="title" resource="itinerary_days" fullWidth />
          <RichTextInput source="content" resource="itinerary_days" fullWidth />
          <Box pt={1}>
            <Button color="primary" variant="contained" type="submit">
              Add
            </Button>
          </Box>
          <br />
        </form>
      )}
    />
  );
};

export const CitiesConnect = ({ tour_id }) => {
  // const [create, { loading }] = useCreate("city_tour");
  const [create] = useCreate("city_tour");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    // console.log({ values });
    if (values.cities.length === 0) return;
    for (let i = 0; i < values.cities.length; i++) {
      create(
        {
          payload: {
            data: { tour_id, city_id: values.cities[i] },
          },
        },
        {
          onSuccess: ({ data }) => {
            // notify("ra.notification.created");
            refresh();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm>
              <ReferenceArrayInput
                label="Cities"
                reference="cities"
                source="cities"
                resource="city_tour"
              >
                <SelectArrayInput fullWidth>
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            </Grid>
            <Grid item sm="auto">
              <Box pt={2}>
                <Button color="primary" variant="contained" type="submit">
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export const ActivitiesConnect = ({ tour_id }) => {
  // const [create, { loading }] = useCreate("activity_tour");
  const [create] = useCreate("activity_tour");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    if (values.activities.length === 0) return;
    for (let i = 0; i < values.activities.length; i++) {
      create(
        {
          payload: {
            data: { tour_id, activity_id: values.activities[i] },
          },
        },
        {
          onSuccess: ({ data }) => {
            // notify("ra.notification.created");
            refresh();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm>
              <ReferenceArrayInput
                label="Activities"
                reference="activities"
                source="activities"
                resource="activity_tour"
              >
                <SelectArrayInput fullWidth>
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            </Grid>
            <Grid item sm="auto">
              <Box pt={2}>
                <Button color="primary" variant="contained" type="submit">
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export const LocationsConnect = ({ tour_id }) => {
  const [create] = useCreate("location_tour");
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async (values) => {
    if (values.locations.length === 0) return;
    for (let i = 0; i < values.locations.length; i++) {
      create(
        {
          payload: {
            data: { tour_id, location_id: values.locations[i] },
          },
        },
        {
          onSuccess: ({ data }) => {
            refresh();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  return (
    <Form
      initialValues={{ tour_id }}
      onSubmit={onSubmit}
      subscription={defaultSubscription} // don't redraw entire form each time one field changes
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item sm>
              <ReferenceArrayInput
                label="Locations"
                reference="locations"
                source="locations"
                resource="location_tour"
              >
                <SelectArrayInput fullWidth>
                  <ChipField source="name" />
                </SelectArrayInput>
              </ReferenceArrayInput>
            </Grid>
            <Grid item sm="auto">
              <Box pt={2}>
                <Button color="primary" variant="contained" type="submit">
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
