import React, { useCallback, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  SaveButton,
  Toolbar,
  useRedirect,
  useNotify,
} from "react-admin";
import { required, minLength, email } from "react-admin";
import firebase from "firebase/app";
import "firebase/functions";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";

const validatetName = [required()];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];

const SaveUserButton = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath } = props;
  const handleSave = useCallback(
    async (values, redirect) => {
      console.log(values);
      const register = await firebase
        .functions()
        .httpsCallable("registerModerator");
      try {
        const result = await register(values);
        notify("ra.notification.created", "info", {
          smart_count: 1,
        });
        redirectTo(redirect, basePath, result.data.id, result.data);
      } catch (e) {
        console.log(e);
        notify(e.message, "error");
      }
    },
    [notify, redirectTo, basePath]
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveUserButton />
  </Toolbar>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" validate={validatetName} />
      <TextInput source="email" type="email" validate={validateEmail} />
      <PasswordInput source="password" validate={validatePassword} />
    </SimpleForm>
  </Create>
);

export const PasswordChange = (props) => {
  // const redirectTo = useRedirect();
  const notify = useNotify();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleSave = async (e) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      var user = firebase.auth().currentUser;
      await user.updatePassword(password);
      notify("ra.notification.updated", "info", {
        smart_count: 1,
      });
    } catch (e) {
      console.log(e);
      notify(e.message, "error");
    }
  };

  const isValid = password && password === passwordConfirm;

  return (
    <form onSubmit={handleSave}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Change Password
          </Typography>
          <TextField
            variant="filled"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <br />
          <TextField
            variant="filled"
            type="password"
            label="Password Confirm"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            disabled={!isValid}
            variant="contained"
            color="primary"
          >
            Change Password
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
