import * as React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import MediaInput from "../components/MediaInput";

const TourTypeTitle = ({ record }) => {
  return <span>Tour Type {record ? `"${record.name}"` : ""}</span>;
};

export const TourTypeList = (props) => (
  <List {...props} filters={<TourTypeFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export const TourTypeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

export const TourTypeEdit = (props) => (
  <Edit {...props} title={<TourTypeTitle />}>
    <TourTypeForm />
  </Edit>
);

export const TourTypeCreate = (props) => (
  <Create {...props}>
    <TourTypeForm />
  </Create>
);
const TourTypeForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" />
    <ReferenceInput
      allowEmpty
      label="Parent Type"
      source="parent_type_id"
      reference="tour_types"
      filter={{ parent_type_id: null }}
    >
      <SelectInput />
    </ReferenceInput>
    <TextInput multiline source="description" />
    <MediaInput source="image_id" />
  </SimpleForm>
);
