import React, { useState, useEffect, useMemo } from "react";
import { Admin, Resource } from "react-admin";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "react-admin-firebase";
// import ApolloClient from "apollo-boost";
// import buildHasuraProvider from "ra-data-hasura-graphql";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  LocationCity,
  AccountCircle,
  Category,
  FlightTakeoff,
  Rowing,
  QuestionAnswer,
  CloudDownload,
  PinDrop,
} from "@material-ui/icons";
import hasuraDataProvider from "ra-data-hasura";
import { Route } from "react-router-dom";

import Dashboard from "./Dashboard";
import { CityCreate, CityEdit, CityList } from "./cities/CityControl";
import { UserList } from "./users/UserList";
import { PasswordChange, UserCreate } from "./users/UserCreate";
import "./App.css";
import {
  ActivityCreate,
  ActivityEdit,
  ActivityList,
} from "./activities/ActivityControl";
import {
  TourTypeCreate,
  TourTypeEdit,
  TourTypeList,
} from "./tour_types/TourTypeControl";
import { TourCreate, TourEdit, TourList, TourShow } from "./tours/TourControl";
import { PriceEdit } from "./prices/PriceControl";
import { DayEdit } from "./itinerary_days/DaysControl";
import { FAQCreate, FAQEdit, FAQList } from "./faqs/FAQControl";
import { AccommodationEdit } from "./accommodation_prices/AccomodationEdit";
import { DeployCreate, DeployList } from "./deploys/DeployControl";
import {
  LocationCreate,
  LocationEdit,
  LocationList,
  LocationShow,
} from "./locations/LocationControl";

const firebaseConfig = {
  apiKey: "AIzaSyC621rlt4lZTSqdnd1-MJXURCs-SeYOJ3w",
  authDomain: "mautours-419ca.firebaseapp.com",
  databaseURL: "https://mautours-419ca.firebaseio.com",
  projectId: "mautours-419ca",
  storageBucket: "mautours-419ca.appspot.com",
  messagingSenderId: "959793669964",
  appId: "1:959793669964:web:ccf0a1797b30fe47ed11e9",
  measurementId: "G-WWBHRR192L",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const authProvider = FirebaseAuthProvider(firebaseConfig, { app: firebase });

// Configure JSS
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff4400",
    },
    secondary: {
      main: "#0044ff",
    },
  },
});

function App() {
  const [authToken, setAuthToken] = useState(null);
  // const [dataProvider, setDataProvider] = useState(null);
  useEffect(() => {
    document.title = "Egyvel";
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        // const role = idTokenResult.claims.role;
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];
        if (hasuraClaim) {
          setAuthToken(token);
        } else {
          setAuthToken(null);
        }
      } else {
        setAuthToken(null);
      }
    });
  }, []);

  const headers = useMemo(() => {
    if (authToken === null) {
      return {
        "content-type": "application/json",
      };
    } else {
      return {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      };
    }
  }, [authToken]);

  return (
    <Admin
      dashboard={Dashboard}
      dataProvider={hasuraDataProvider("https://api.egyvel.com/", headers)}
      authProvider={authProvider}
      theme={theme}
      title="Egyvel Admin"
      customRoutes={[
        <Route
          key="change-password"
          path="/change-password"
          component={PasswordChange}
        />,
      ]}
    >
      <Resource
        icon={FlightTakeoff}
        name="tours"
        list={TourList}
        edit={TourEdit}
        create={TourCreate}
        show={TourShow}
      />
      <Resource name="prices" edit={PriceEdit} />
      <Resource name="itinerary_days" edit={DayEdit} />
      <Resource name="accommodation_prices" edit={AccommodationEdit} />
      <Resource name="city_tour" />
      <Resource name="activity_tour" />
      <Resource name="tour_gallery" />
      <Resource name="location_tour" />
      <Resource name="location_gallery" />
      <Resource name="images" />
      <Resource
        icon={LocationCity}
        name="cities"
        list={CityList}
        edit={CityEdit}
        create={CityCreate}
      />
      <Resource
        icon={PinDrop}
        name="locations"
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
        show={LocationShow}
      />
      <Resource
        icon={Rowing}
        name="activities"
        list={ActivityList}
        edit={ActivityEdit}
        create={ActivityCreate}
      />
      <Resource
        icon={Category}
        name="tour_types"
        list={TourTypeList}
        edit={TourTypeEdit}
        create={TourTypeCreate}
      />
      <Resource
        icon={QuestionAnswer}
        name="faqs"
        list={FAQList}
        create={FAQCreate}
        edit={FAQEdit}
      />
      <Resource
        icon={AccountCircle}
        name="users"
        list={UserList}
        create={UserCreate}
      />
      <Resource
        icon={CloudDownload}
        name="deploys"
        list={DeployList}
        create={DeployCreate}
      />
    </Admin>
  );
}

export default App;
