import * as React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const FAQTitle = ({ record }) => {
  return <span>FAQ {record ? `"${record.question}"` : ""}</span>;
};

export const FAQList = (props) => (
  <List {...props} filters={<FAQFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="question" />
    </Datagrid>
  </List>
);

export const FAQFilter = (props) => (
  <Filter {...props}>
    <TextInput source="question" />
  </Filter>
);

export const FAQEdit = (props) => (
  <Edit {...props} title={<FAQTitle />}>
    <FAQForm />
  </Edit>
);

export const FAQCreate = (props) => (
  <Create {...props}>
    <FAQForm />
  </Create>
);
const FAQForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="question" />
    <RichTextInput source="answer" />
  </SimpleForm>
);
