import * as React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const AccommodationEdit = (props) => (
  <Edit {...props}>
    <AccommodationForm />
  </Edit>
);

const AccommodationForm = (props) => {
  const tour_id = props.record ? props.record.tour_id : undefined;
  const redirect = tour_id ? `/tours/${tour_id}/show/accommodations` : false;

  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput label="Tour" source="tour_id" reference="tours">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="name"
        resource="accommodation_prices"
        choices={[
          { id: "silver", name: "Silver" },
          { id: "gold", name: "Gold" },
          { id: "diamond", name: "Diamond" },
        ]}
      />
      <TextInput source="venues" resource="accommodation_prices" />
      <NumberInput
        label="Summer Single"
        source="summer_single_room"
        resource="accommodation_prices"
      />
      <NumberInput
        label="Summer Double"
        source="summer_double_room"
        resource="accommodation_prices"
      />
      <NumberInput
        label="Summer Triple"
        source="summer_triple_room"
        resource="accommodation_prices"
      />
      <NumberInput
        label="Winter Single"
        source="winter_single_room"
        resource="accommodation_prices"
      />
      <NumberInput
        label="Winter Double"
        source="winter_double_room"
        resource="accommodation_prices"
      />
      <NumberInput
        label="Winter Triple"
        source="winter_triple_room"
        resource="accommodation_prices"
      />
    </SimpleForm>
  );
};
