import { useCreate, useNotify, useRefresh } from "ra-core";
import { Form } from "react-final-form";
import {
  Datagrid,
  DeleteButton,
  ReferenceField,
  ReferenceManyField,
} from "react-admin";
import { Box, Button, Grid } from "@material-ui/core";
import MediaInput from "./MediaInput";

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

const GalleryTab = (props) => {
  const [create] = useCreate(props.url);
  const notify = useNotify();
  const refresh = useRefresh();

  const target = parseInt(props.record.id);
  const onSubmit = async (values) => {
    if (values.images.length === 0) return;
    for (let i = 0; i < values.images.length; i++) {
      create(
        {
          payload: {
            data: { [props.target]: target, image_id: values.images[i] },
          },
        },
        {
          onSuccess: ({ data }) => {
            refresh();
          },
          onFailure: ({ error }) => {
            notify(error.message, "error");
          },
        }
      );
    }
  };

  return (
    <div>
      <Form
        initialValues={{ [props.target]: target }}
        onSubmit={onSubmit}
        subscription={defaultSubscription} // don't redraw entire form each time one field changes
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item sm>
                <MediaInput source="images" multiple />
              </Grid>
              <Grid item sm="auto">
                <Box pt={2}>
                  <Button color="primary" variant="contained" type="submit">
                    Add
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <ReferenceManyField
        {...props}
        reference={props.url}
        target={props.target}
      >
        <Datagrid>
          <ReferenceField source="image_id" reference="images">
            <CustomImageField />
          </ReferenceField>
          <DeleteButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

export const CustomImageField = ({ record }) => {
  return (
    <img
      src={`https://res.cloudinary.com/dnacgxznx/image/upload/${record.path}.${record.type}`}
      alt="select"
      style={{
        width: "50px",
        height: "50px",
        objectFit: "cover",
      }}
    />
  );
};

export default GalleryTab;
