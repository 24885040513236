import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { Add } from "@material-ui/icons";
import { useCreate, useGetOne, useNotify } from "ra-core";
import { ReferenceArrayInput } from "react-admin";

const CustomImageField = ({ image_id }) => {
  const { data, loading, error } = useGetOne("images", image_id);
  if (loading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <img
      src={`https://res.cloudinary.com/dnacgxznx/image/upload/${data.path}.${data.type}`}
      alt="select"
      style={{
        width: "100px",
        height: "100px",
        objectFit: "cover",
        display: "block",
      }}
    />
  );
};

const MediaInput = ({ source, multiple = false }) => {
  const [version, setVersion] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const onUpload = () => setVersion((old) => old + 1);

  return (
    <Field name={source}>
      {(props) => (
        <>
          {props.input.value && !multiple && (
            <CustomImageField image_id={props.input.value} />
          )}
          <Button onClick={handleClick} label="ra.action.create">
            <Add />
            Choose Image
          </Button>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={showDialog}
            onClose={handleCloseClick}
            aria-label="Select Image"
          >
            <DialogTitle>Select Image</DialogTitle>
            <DialogContent>
              <ImageUpload onChange={onUpload} />
              <hr />
              <ReferenceArrayInput
                key={version}
                label="images"
                resource="images"
                source="images"
                reference="images"
                perPage={100}
              >
                <ImageGallery
                  multiple={multiple}
                  value={props.input.value}
                  onChange={(val) => props.input.onChange(val)}
                />
              </ReferenceArrayInput>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Field>
  );
};

export const ImageGallery = ({ choices, value, onChange, multiple }) => {
  const isSelected = (id) => {
    if (multiple) {
      return value.indexOf(id) !== -1;
    } else {
      return parseInt(value) === parseInt(id);
    }
  };

  return (
    <Grid container spacing={2}>
      {choices.map((image) => (
        <Grid
          item
          sm={2}
          key={image.id}
          onClick={() => {
            if (multiple) {
              if (Array.isArray(value)) {
                const exists = value.find((i) => i === image.id);
                if (exists) {
                  onChange(value.filter((i) => i !== image.id));
                } else {
                  onChange([...value, image.id]);
                }
              } else {
                onChange([image.id]);
              }
            } else {
              onChange(image.id);
            }
          }}
        >
          <img
            src={`https://res.cloudinary.com/dnacgxznx/image/upload/${image.path}.${image.type}`}
            alt="select"
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              border: isSelected(image.id)
                ? "4px solid blue"
                : "4px solid white",
            }}
          />
          <p>{image.title}</p>
        </Grid>
      ))}
    </Grid>
  );
};

export const ImageUpload = ({ onChange }) => {
  // const [create, { loading }] = useCreate("images");
  const [create] = useCreate("images");
  const notify = useNotify();

  const onSubmit = async (e) => {
    const url = "https://api.cloudinary.com/v1_1/dnacgxznx/upload";
    const files = e.target.files;
    if (files.length === 0) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append("file", file);
      formData.append("upload_preset", "nandsjwg");

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          create(
            {
              payload: {
                data: {
                  path: data.public_id,
                  title: "",
                  type: data.format,
                },
              },
            },
            {
              onSuccess: ({ data }) => {
                onChange();
              },
              onFailure: ({ error }) => {
                notify(error.message, "error");
              },
            }
          );
        });
    }
  };

  // const onSubmit = async (e) => {
  //   const files = e.target.files;
  //   if (files.length === 0) return;
  //   const storageRef = firebase.storage().ref();
  //   for (var i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     console.log("file", file);
  //     const filename = Date.now();
  //     const imageRef = storageRef.child("images/" + filename);
  //     try {
  //       const snapshot = await imageRef.put(file);
  //       const file_url = await snapshot.ref.getDownloadURL();
  //       create(
  //         {
  //           payload: {
  //             data: {
  //               path: file_url,
  //               title: "",
  //               type: file.name.split(".").pop(),
  //             },
  //           },
  //         },
  //         {
  //           onSuccess: ({ data }) => {
  //             refresh();
  //           },
  //           onFailure: ({ error }) => {
  //             notify(error.message, "error");
  //           },
  //         }
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  /* <input name="file" type="file"
   class="file-upload" data-cloudinary-field="image_id"
   data-form-data="{ 'transformation': {'crop':'limit','tags':'samples','width':3000,'height':2000}}"/> */
  //  Cloud name: dnacgxznx
  //  API key: 486375928362977
  //  API secret: WRZWcuTGiD3JWC7zEilMkygbnfU
  //  Environment variable: CLOUDINARY_URL=cloudinary://486375928362977:WRZWcuTGiD3JWC7zEilMkygbnfU@dnacgxznx

  return (
    <input type="file" multiple accept=".png,.jpg,.jpeg" onChange={onSubmit} />
  );
};

export default MediaInput;
