import {
  Edit,
  Datagrid,
  Filter,
  List,
  ShowButton,
  TextInput,
  TextField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  Show,
  TabbedShowLayout,
  Tab,
  RichTextField,
} from "react-admin";
import MediaInput from "../components/MediaInput";
import RichTextInput from "ra-input-rich-text";
import GalleryTab from "../components/GalleryTab";

export const TourFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

export const LocationList = (props) => (
  <List {...props} filters={<TourFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="name" />
      {/* <TextField source="description" /> */}
      <ShowButton />
    </Datagrid>
  </List>
);

const LocationTitle = ({ record }) => {
  return <span>Location {record ? `"${record.name}"` : ""}</span>;
};

export const LocationEdit = (props) => (
  <Edit {...props} title={<LocationTitle />}>
    <LocationForm />
  </Edit>
);

export const LocationCreate = (props) => {
  return (
    <Create {...props}>
      <LocationForm />
    </Create>
  );
};

const LocationForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" fullWidth />
      <ReferenceInput label="City" source="city_id" reference="cities">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="description" multiline fullWidth />
      <RichTextInput source="details" />
      <MediaInput source="image_id" />
    </SimpleForm>
  );
};

export const LocationShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="name" />
          <RichTextField source="description" />
        </Tab>
        <Tab label="Gallery" path="galleries">
          <GalleryTab url="location_gallery" target="location_id" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
