import * as React from "react";
import { Link } from "react-admin";
// import Logo from "./logo.png";
import firebase from "firebase/app";

export default function Dashboard() {
  const user = firebase.auth().currentUser;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        flexDirection: "column",
      }}
    >
      {/* <img src={Logo} alt="MauTours Logo" title="logo" /> */}
      <br />
      <br />
      <h2>Welcome, {user && user.displayName}</h2>
      <Link to="/change-password">Change Password</Link>
    </div>
  );
}
