import * as React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";

export const PriceList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <ReferenceField source="tour_id" reference="tours">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="num_of_persons" />
      <NumberField source="price" />
    </Datagrid>
  </List>
);

export const PriceEdit = (props) => (
  <Edit {...props}>
    <PriceForm />
  </Edit>
);

export const PriceCreate = (props) => (
  <Create {...props}>
    <PriceForm />
  </Create>
);

const PriceForm = (props) => {
  const tour_id = props.record ? props.record.tour_id : undefined;
  const redirect = tour_id ? `/tours/${tour_id}/show/prices` : false;

  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput label="Tour" source="tour_id" reference="tours">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="num_of_persons" />
      <NumberInput source="price" />
    </SimpleForm>
  );
};
