import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  List,
  Datagrid,
  TextField,
  Filter,
  DateField,
  ReferenceField,
} from "react-admin";

export const DeployList = (props) => (
  <List {...props} filters={<DeployFilter />}>
    <Datagrid rowClick="edit" optimized>
      <ReferenceField reference="users" source="user_id">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="description" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const DeployFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

export const DeployCreate = (props) => (
  <Create {...props}>
    <DeployForm />
  </Create>
);
const DeployForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="description" />
  </SimpleForm>
);
