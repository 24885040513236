import * as React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

export const DayList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <ReferenceField source="tour_id" reference="tours">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
    </Datagrid>
  </List>
);

export const DayEdit = (props) => (
  <Edit {...props}>
    <DayForm />
  </Edit>
);

export const DayCreate = (props) => (
  <Create {...props}>
    <DayForm />
  </Create>
);

const DayForm = (props) => {
  const tour_id = props.record ? props.record.tour_id : undefined;
  const redirect = tour_id ? `/tours/${tour_id}/show/days` : false;

  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput label="Tour" source="tour_id" reference="tours">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <RichTextInput source="content" />
    </SimpleForm>
  );
};
