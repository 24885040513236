import * as React from "react";
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  SelectInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Grid } from "@material-ui/core";
import MediaInput from "../components/MediaInput";
import GalleryTab from "../components/GalleryTab";

import {
  PriceCreate,
  DayCreate,
  CitiesConnect,
  ActivitiesConnect,
  AccomodationCreate,
  LocationsConnect,
} from "./TourRelations";

export const TourFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

export const TourList = (props) => (
  <List {...props} filters={<TourFilter />}>
    <Datagrid rowClick="edit" optimized>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const TourShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Summary">
          {/* <TextField source="id" /> */}
          <TextField source="name" />
          <TextField source="description" />
          <RichTextField source="itinerary" />
          <RichTextField source="included" />
          <RichTextField source="excluded" />
          <RichTextField source="optionals" />
        </Tab>
        <Tab label="Gallery" path="galleries">
          <GalleryTab url="tour_gallery" target="tour_id" />
        </Tab>
        <Tab label="Prices" path="prices">
          <ReferenceManyField
            reference="prices"
            target="tour_id"
            sort={{ field: "num_of_persons", order: "ASC" }}
          >
            <Datagrid>
              <NumberField source="num_of_persons" />
              <NumberField source="price" />
              <EditButton />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <PriceCreate tour_id={parseInt(props.id)} />
        </Tab>
        <Tab label="Accommodations" path="accommodations">
          <ReferenceManyField
            reference="accommodation_prices"
            target="tour_id"
            sort={{ field: "name", order: "DESC" }}
          >
            <Datagrid>
              <NumberField source="name" />
              <EditButton />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <AccomodationCreate tour_id={parseInt(props.id)} />
        </Tab>
        <Tab label="Itinerary Days" path="days">
          <ReferenceManyField
            reference="itinerary_days"
            target="tour_id"
            sort={{ field: "id", order: "ASC" }}
          >
            <Datagrid>
              <TextField source="title" />
              <EditButton />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <DayCreate tour_id={parseInt(props.id)} />
        </Tab>
        <Tab label="Cities and Activities" path="cities-activities">
          <ConnectionsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ConnectionsTab = (props) => {
  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <CitiesConnect tour_id={parseInt(props.record.id)} />
        <ReferenceManyField {...props} reference="city_tour" target="tour_id">
          <Datagrid>
            <ReferenceField source="city_id" reference="cities">
              <TextField source="name" />
            </ReferenceField>
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item sm={4}>
        <ActivitiesConnect tour_id={parseInt(props.record.id)} />
        <ReferenceManyField
          {...props}
          reference="activity_tour"
          target="tour_id"
        >
          <Datagrid>
            <ReferenceField source="activity_id" reference="activities">
              <TextField source="name" />
            </ReferenceField>
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
      <Grid item sm={4}>
        <LocationsConnect tour_id={parseInt(props.record.id)} />
        <ReferenceManyField
          {...props}
          reference="location_tour"
          target="tour_id"
        >
          <Datagrid>
            <ReferenceField source="location_id" reference="locations">
              <TextField source="name" />
            </ReferenceField>
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Grid>
    </Grid>
  );
};

const TourTitle = ({ record }) => {
  return <span>Tour Type {record ? `"${record.name}"` : ""}</span>;
};

export const TourEdit = (props) => (
  <Edit {...props} title={<TourTitle />}>
    <TourForm />
  </Edit>
);

export const TourCreate = (props) => {
  return (
    <Create {...props}>
      <TourForm />
    </Create>
  );
};

const TourForm = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" fullWidth />
      <ReferenceInput
        label="Tour Type"
        source="tour_type_id"
        reference="tour_types"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="description" fullWidth />
      <MediaInput source="image_id" />
      <RichTextInput source="itinerary" />
      <RichTextInput source="included" />
      <RichTextInput source="excluded" />
      <RichTextInput source="optionals" />
    </SimpleForm>
  );
};
